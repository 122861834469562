<template>
  <vx-card class="p-4">
    <form action="">
      <div class="vx-row mb-6">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.code') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required_if:isBlocked,false'" name="code" class="w-full" v-model="wholesaler.code" placeholder="101012345" />
          <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.username') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required'" class="w-full" name="username" v-model="wholesaler.username" placeholder="User name" />
          <span class="text-danger text-sm" v-show="errors.has('username')">{{ errors.first('username') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.name') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="name_tm" :placeholder="$t('fields.name')" v-model="wholesaler.name" />
            <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.phone') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <div class="vx-col my-2 w-full">
            <vs-input v-validate="'required'" class="w-full" name="phone" placeholder="Turkmen" v-model="wholesaler.phone" />
            <span class="text-danger text-sm" v-show="errors.has('phone')">{{ errors.first('phone') }}</span>
          </div>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('fields.email') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required_if:isBlocked,false'" name="email" class="w-full" v-model="wholesaler.email" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{ errors.first('email') }}</span>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('passowrd') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <vs-input v-validate="'required|min:6'" name="password" type="password" class="w-full" v-model="wholesaler.password" />
          <span class="text-danger text-sm" v-show="errors.has('password')">{{ errors.first('password') }}</span>
        </div>
      </div>
      
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('isBlocked') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" ref="isBlocked" v-model="wholesaler.isBlocked" />
            <label class="ml-3">{{ $t('block') }}?</label>
          </li>
        </div>
      </div>
      <div class="vx-row mb-6 mt-4">
        <div class="vx-col w-full sm:w-2/12 pt-2">
          <span class="text-lg font-semibold">{{ $t('props.isCredited') }}:</span>
        </div>
        <div class="vx-col sm:w-10/12 w-full">
          <li class="vx-col my-2 ml-2 mr-4 flex">
            <vs-switch name="isBulk" ref="isBlocked" v-model="wholesaler.isCredited" />
            <label class="ml-3">{{ $t('props.isCredited') }}?</label>
          </li>
        </div>
      </div>
      <vs-row>
        <vs-button type="filled" @click.prevent="submitForm" class="ml-auto">{{ $t('submit') }}</vs-button>
        <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 mr-auto block">{{ $t('cancel') }}</vs-button>
      </vs-row>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      wholesaler: {},
      types: [
        {name_en: 'both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {name_en: 'market', name_tm: 'Market', name_ru: 'Маркет'},
        {name_en: 'express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ]
    }
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/wholesalers/add', {
            ...this.wholesaler
          }).then(response => {
            if (response.status === 201) {
              this.notify(this.$t('notify.success'), `${this.$t('wholesaler')} ${this.$t('notify.sCreated')}`, 'icon-check-circle', 'primary')
              this.$router.back()
            }
          }).catch(err => { this.notify(this.$t('notify.error'), err.message, 'icon-alert-circle', 'danger') })
        } else { this.notify(this.$t('notify.error'), this.$t('notify.formInvalid'), 'icon-alert-circle', 'danger') }
      })
    },
    notify (title, text, icon, color) {
      this.$vs.notify({ title, text, iconPack: 'feather', icon, color })
    }
  }
}
</script>
